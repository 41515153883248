<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 运营商管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">运营商列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteData">
							删除
						</a-button>
						
					</div>
				</div>

				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :pagination="{total: total_page * 10}"
				 :data-source="corpList" rowKey="corp_id" size="small" @change="handleTableChange">
				 <template #action="{ address }">
				<span>
					<a-space size="small">
						<a-button type="primary" @click="edit(record)">
						编辑
						</a-button>
						<a-button type="danger">
							删除
						</a-button>
					</a-space>
				</span>
					</template>
				</a-table>
			</div>
		</div>


		<a-modal v-model="addEditShow" :title="isAdd ? '新增运营商' : '编辑运营商'" :footer="null">
			<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="名称：" prop="corp_name">
							<a-input v-model="info.corp_name"  placeholder="请输入运营商名称"/>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="联系人：" prop="contact_name">
							<a-input v-model="info.contact_name"  placeholder="请输入联系人"/>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="联系方式：" prop="contact_phone">
							<a-input v-model="info.contact_phone"  placeholder="请输入联系方式"/>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="选择省市区：" prop="province">
							<area-select @change="selectArea"  :default-value="info.province ? [info.province , info.city, info.district] : []" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="联系地址：" prop="address">
							<a-input v-model="info.address"  placeholder="请输入详细联系地址"/>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="add()">
						保存
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="updateCorp">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	const columns = [
		{
			title: "名称",
			dataIndex: "corp_name",
		},
		{
			title: "联系人",
			dataIndex: "contact_name",
		},
		{
			title: "联系方式",
			dataIndex: "contact_phone",
		},
		{
			title: "联系地址",
			dataIndex: "address",
		}
	];
	import AreaSelect from "@/components/City/index.vue";
	import http from '../http'
	export default {
		data() {
			return {
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				show1: false,
				show4: false,
				// 编辑与添加
				addEditShow: false,
				// 是否添加状态
				isAdd: false,
				// 搜索
				searchData: {
					corp_name: '',
					page: 1
				},
				// 运营商列表
				corpList: [],
				// 多少条数据 * 10
				total_page: 0,

				// 添加与编辑的数据
				info: {
					province: '',
					city:0,
					district: '',
					corp_name: '',
					corp_id: 0,
					contact_name: '',
					contact_phone: '',
					address:''
				},
				rules: {
					corp_name: [{
						required: true,
						message: '请输入运营商名称',
						trigger: 'blur'
					}, ],
					contact_name: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}, ],
					contact_phone: [{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					}, ],
				},
				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0
			}; 
		},
		components: {
			AreaSelect
		},
		mounted() {
			this.getList()
		},
		methods: {
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},
			// 表格分页
			handleTableChange(pagination, filters, sorter) {
				this.searchData.page = pagination.current
				this.getList();
			},

			handleReset() {
				this.form.resetFields();
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.info = this.selectedRowDataList[this.editIndex]
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			//选择地区之后的回调
			selectArea(selectedArea) {
				this.info.province = selectedArea[0]
				this.info.city = selectedArea[1]
				this.info.district = selectedArea[2]
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						province: '',
						city:0,
						district: '',
						corp_name: '',
						corp_id: 0,
						contact_name: '',
						contact_phone: '',
						address:''
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择运营商');
						return
					}

					let selectDatas = []
					this.corpList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.corp_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					this.info = this.selectedRowDataList[0]
					this.isAdd = false
				}

				this.addEditShow = true
			},
			// 搜索
			search() {
				this.searchData.page = 1
				this.getList()
			},
			// 运营商列表
			getList() {
				http({
					method: 'post',
					url: '/api.corp.list/',
					data: this.searchData
				}).then((res) => {
					this.corpList = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 
			deleteData() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择运营商');
					return
				}

				this.$confirm({
					title: '是否删除选中运营商?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk : () => {
						let selectDatas = []
						this.corpList.forEach((v) => {
							if(this.selectedRowKeys.includes(v.corp_id)) {
								selectDatas.push(v.corp_id)
							}
						})
						http({
							method: 'post',
							url: '/api.corp.del/',
							data: {
								corp_id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除运营商成功');
								this.getList()
							} else {
								this.$message.error(res.message);
							}
						})
					}
				});


			},
			// 添加运营商
			add (type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.corp.add/',
							data: {...this.info}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改运营商信息成功' : '添加运营商成功');
								this.getList()
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 更新运营商
			updateCorp() {
				this.add('edit')
			}
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.tes {
		width: 90%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.table-th {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;
			height: 30px;

			div {
				width: 33.33%;
				box-sizing: border-box;
				height: 30px;
				padding-left: 20px;
				font-weight: 700;
				border-right: 1px solid #e8e8e8;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}
		}

		.taes {
			display: flex;
			align-items: center;

			&>div {
				width: 33.33%;
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 50px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 20px;
				}
			}

			.left {
				height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				height: 300px;
				border-right: 1px solid #e8e8e8;
			}

			.right {
				height: 300px;
			}
		}
	}
</style>
